import React from 'react'
import { LazySection } from '..'
import { DataLink, RichTextField } from '../prismic-elements'
import datastore from '../../datastore'

import './SectorsGrid.sass'

const SectorsGrid = ({ slice }) => {
  return (
    <LazySection className='section section-grid-cards slice-sectors-grid'>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <div className='section-grid-cards-container'>
          {
            datastore.visiblesectors.map((item, index) => {
              return (
                <DataLink
                  prismicLink={item}
                  key={index}
                  className='section-grid-cards-item'
                  style={{ '--background': `url(${item.data.thumbnail.url})` }}
                >
                  <div className='section-grid-cards-item-inner'>
                    <RichTextField field={item.data.title} Component='h3' className='section-grid-cards-item-title' />
                  </div>
                </DataLink>
              )
            })
          }
        </div>
      </div>
    </LazySection>
  )
}

export default SectorsGrid
