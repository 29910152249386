import React from 'react'
import { Carousel, LazySection } from '../'
import { DataLink, RichTextField, FirstParagraph } from '../prismic-elements'
import { animated } from 'react-spring'
import { useTiltEffect } from '../../utils/useTiltEffect'
import datastore from '../../datastore'

import './SectorsList.sass'

const SectorsList = ({ slice }) => {
  return (
    <LazySection className='section section-cards slice-sectors-list'>
      <div className='w-container'>
        <RichTextField field={slice.primary.title} Component='h2' className='section-title' />
        <Carousel isSlideShow slideShowInterval={5000} className='section-cards-container' pagerClassName='pager' pagerItemClassName='pager-item'>
          {
            datastore.visiblesectors.map((item, index) => {
              return (
                <DataLink
                  prismicLink={item}
                  key={index}
                  style={{ '--background': `url(${item.data.thumbnail.url})` }}
                >
                  <Sector item={item} />
                </DataLink>
              )
            })
          }
        </Carousel>
      </div>
    </LazySection>
  )
}

const Sector = React.forwardRef(({ item }, fwdRef) => {
  const ref = fwdRef || React.useRef(null)
  const { style, ...mouseHandlers } = useTiltEffect(ref)

  return (
    <animated.div
      ref={ref}
      style={style}
      {...mouseHandlers}
      className='section-cards-item'
    >
      <RichTextField field={item.data.title} Component='h3' />
      <FirstParagraph className='section-cards-item-text' richText={item.data.description} textLimit={140} />
    </animated.div>
  )
})

export default SectorsList
